import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { AskComponent } from './ask.component';

@NgModule({
  declarations: [AskComponent],
  imports: [CommonModule, MatButtonModule, TranslateModule.forChild()],
  exports: [AskComponent],
})
export class PopupAskModule {}
